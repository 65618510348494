import TabPanel from '../TabPanel';
import ListFooter from '../ListFooter';
import { Grid } from '@material-ui/core';
import React, { Component } from 'react';
import List from '@material-ui/core/List';
import ReportingComponent from './reports';
import EuroIcon from '@material-ui/icons/Euro';
import * as ROLES from '../../constants/roles';
import * as COLORS from '../../constants/colors';
import Backdrop from '@material-ui/core/Backdrop';
import Collapse from '@material-ui/core/Collapse';
import PolicyIcon from '@material-ui/icons/Policy';
import PeopleIcon from '@material-ui/icons/People';
import PartnerTableComponent from './partnerTable';
import PaymentIcon from '@material-ui/icons/Payment';
import { STEERING } from '../../constants/realtimeDB';
import EmployeeTableComponent from './employeeTable';
import CssBaseline from '@material-ui/core/CssBaseline';
import CaptableRoundDetails from './captableRoundDetails';
import AssessmentIcon from '@material-ui/icons/Assessment';
import DescriptionIcon from '@material-ui/icons/Description';
import ChartersAndPoliciesComponent from './chartersPolicies';
import CircularProgress from '@material-ui/core/CircularProgress';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import CustomListItem from '../utils/GenericComponents/customListItem';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';

import LowTouchMRR from './lowTouchMrr';
import { SUB_PANELS } from './lowTouchMrr';

const ALL = 1;
const EXCEPT_PARTNERINFO = 2;

const sanitizeUser = (user, option) => {
  delete user.ndf;
  delete user.pnl;
  delete user.own;
  delete user.uid;
  delete user.email;
  delete user.roles;
  delete user.trigram;
  delete user.ownEsops;
  delete user.ownShares;
  delete user.totalInvestment;
  
  if (option !== EXCEPT_PARTNERINFO)
    delete user.partnerInfo;

  return user;
}

class AdminPageBase extends Component {

    constructor(props) {
      super(props);
      this.state = {
        users: [],
        rounds: [],
        completed: 0,
        loading: false,
        selectedIndex: 0,
        //selectedIndex: 8,
        steeringObj : null,
        payoutExpanded : false,
        captableExpanded : false,
      };
      this.classes = props.classes;
      this.firebase = props.firebase;
    }
  
    componentDidMount() {
      this.setState({ loading: true });
  
      this.firebase.users().on('value', snapshot => {
        const usersObjectFromDB = snapshot.val();
        const usersList = Object.keys(usersObjectFromDB).map(key => ({ uid: key, ...usersObjectFromDB[key] }));
        this.setState({ users: usersList });
      });
  
      this.firebase.db.ref(STEERING).on('value', snapshot => {
        const steeringObjFromDB = snapshot.val();
        const shares = steeringObjFromDB.SHARES;
        const roundList = Object.keys(shares).map(key => ({ round: key, ...shares[key] }));
        roundList.sort( (a, b) => (a.order > b.order) ? 1 : -1);
        this.setState({ rounds: roundList, steeringObj: steeringObjFromDB });
      });
  
      this.setState({ loading: false });
    }
    
    onSaveUser = (event, user) => {
      user.contract["on_call"] = event;
      if (!('called' in user.contract))
        user.contract["called"] = 0;
      this.firebase.user(user.uid).update(sanitizeUser( {...user} , ALL));
    }

    componentWillUnmount() {
      this.firebase.users().off();
      this.firebase.db.ref(STEERING).off();
    }
  
    handleClickPayout = () => this.setState({payoutExpanded: !this.state.payoutExpanded});
    handleClickCaptable = () => this.setState({captableExpanded: !this.state.captableExpanded});
    handleListItemClick = (event, index) => this.setState({ selectedIndex: index });  
    onSaveUserWithPartnerInfos = (user) => this.firebase.user(user.uid).update(sanitizeUser( {...user} , EXCEPT_PARTNERINFO));
      
    render() {
  
      const { users, loading, rounds, steeringObj, selectedIndex, payoutExpanded, captableExpanded } = this.state;
  
      const partners = users.filter(user => user?.partnerInfo );
      const employees = users.filter(user => ( user?.roles?.[ROLES.EMPLOYEE] ));
      
      const sm = (this.props.width === 'sm' );

      return (
        <div className={this.classes.divRoot}>
  
          <CssBaseline />

          <Backdrop className={this.classes.backdrop} open={loading} >
            <CircularProgress color="inherit" />
          </Backdrop> 
  
          <Grid container spacing={0} >
  
            <Grid item xs={1} sm={sm ? 1 : 2}>

                <List
                  dense={true}
                  component="nav"
                  id='list-container'
                  aria-label="partner-info-list"
                  className={this.classes.listPanel}
                >
                  <CustomListItem
                    index={0}
                    title="Partners"
                    selectedIndex={selectedIndex}
                    headerIcon={<SupervisedUserCircleIcon />}
                    handleListItemClick={this.handleListItemClick}
                  />

                  <CustomListItem
                    index={1}
                    nestable={captableExpanded}
                    title="Captable"
                    headerIcon={<MonetizationOnIcon />}
                    handleListItemClick={this.handleClickCaptable}
                  />
                  
                  <Collapse in={captableExpanded} timeout="auto" unmountOnExit>  
                    {rounds.map( round => (
                      <CustomListItem
                        nested={true}
                        key={round.round}
                        index={round.order}
                        title={`${round.round} ROUND`}
                        selectedIndex={selectedIndex}
                        handleListItemClick={this.handleListItemClick}
                        headerIcon={<MonetizationOnIcon style={{color: (round.funded) ? COLORS.GREEN : (round.started) ? COLORS.YELLOW : COLORS.LIGHT_GRAY}}/>}
                      />
                    ))}
                  </Collapse>

                  <CustomListItem
                    index={rounds.length + 1}
                    title="Employees"
                    headerIcon={<PeopleIcon />}
                    selectedIndex={selectedIndex}
                    handleListItemClick={this.handleListItemClick}
                  />

                  <CustomListItem
                    index={rounds.length + 2}
                    title="Reporting"
                    selectedIndex={selectedIndex}
                    headerIcon={<DescriptionIcon />}
                    handleListItemClick={this.handleListItemClick}
                  />

                  <CustomListItem
                    index={rounds.length + 3}
                    title={"Charters & Policies"}
                    headerIcon={<PolicyIcon />}
                    selectedIndex={selectedIndex}
                    handleListItemClick={this.handleListItemClick}
                  />

                  <CustomListItem
                    title="SaaS Payouts"
                    index={rounds.length + 4}
                    nestable={payoutExpanded}
                    headerIcon={<PaymentIcon />}
                    selectedIndex={selectedIndex}
                    handleListItemClick={this.handleClickPayout}
                  />
                  
                  <Collapse in={payoutExpanded} timeout="auto" unmountOnExit>  
                      <CustomListItem
                        nested={true}
                        title={`FIFO`}
                        index={rounds.length + 5}
                        headerIcon={<EuroIcon />}
                        selectedIndex={selectedIndex}
                        handleListItemClick={this.handleListItemClick}
                      />
                      <CustomListItem
                        nested={true}
                        title={`MRR`}
                        index={rounds.length + 6}
                        headerIcon={<AssessmentIcon />}
                        selectedIndex={selectedIndex}
                        handleListItemClick={this.handleListItemClick}
                      />
                  </Collapse>

                  <ListFooter sm={sm} firebase={this.props.firebase} />

                </List>
              </Grid>
  
              <Grid item xs={11} sm={sm ? 11 : 10}>
  
                <div className={this.classes.rightPanel}>

                  {selectedIndex === 0 &&
                    <TabPanel value={selectedIndex} index={0}>
                      {steeringObj && (<PartnerTableComponent
                        {...this.props}
                        rounds={rounds}
                        partners={partners}
                        firebase={this.firebase}
                        steeringObj={steeringObj}
                        onSaveUserWithPartnerInfos={this.onSaveUserWithPartnerInfos}
                      />)}
                    </TabPanel>
                  }
    
                  {(partners.length > 0) && rounds.map( round => ( selectedIndex === round.order && 
                      <TabPanel key={round.round} value={selectedIndex} index={round.order}>
                        <CaptableRoundDetails
                          round={round}
                          rounds={rounds}
                          partners={partners}
                          firebase={this.firebase}
                          steeringObj={steeringObj}
                          onSaveUserWithPartnerInfos={this.onSaveUserWithPartnerInfos}
                        />
                      </TabPanel>
                  ))}
      
                  {selectedIndex === (rounds.length + 1) &&
                    <TabPanel value={selectedIndex} index={rounds.length + 1}>
                      {steeringObj && (<EmployeeTableComponent
                        {...this.props}
                        rounds={rounds}
                        employees={employees}
                        firebase={this.firebase}
                        steeringObj={steeringObj}
                        onSaveUser={this.onSaveUser}
                      />)}
                    </TabPanel>
                  }

                  {selectedIndex === (rounds.length + 2) &&
                    <TabPanel value={selectedIndex} index={rounds.length + 2}>
                      <ReportingComponent {...this.props} />
                    </TabPanel>
                  }

                  {selectedIndex === (rounds.length + 3) &&
                    <TabPanel value={selectedIndex} index={rounds.length + 3}>
                      <ChartersAndPoliciesComponent {...this.props} />
                    </TabPanel>
                  }

                  {selectedIndex === (rounds.length + 5) &&
                    <TabPanel value={selectedIndex} index={rounds.length + 5}>
                      <LowTouchMRR {...this.props} tab={SUB_PANELS.PAYOUTS} />
                    </TabPanel>
                  }

                  {selectedIndex === (rounds.length + 6) &&
                    <TabPanel value={selectedIndex} index={rounds.length + 6}>
                      <LowTouchMRR {...this.props} tab={SUB_PANELS.MRR_RECAP} />
                    </TabPanel>
                  }

                </div>
              </Grid>
            </Grid>
  
        </div>
      );
    }
}

export default AdminPageBase;
  