import Payout from './payouts';
import MRRRecap from './mrrRecapPanel';
import React, { useState, useEffect, useMemo, useCallback } from 'react';

const SUB_PANELS = {
    PAYOUTS: 'payouts',
    MRR_RECAP: 'mrrRecap',
};

const LowTouchMRR = (props) => {
    const { firebase, tab } = props;
    const startDate = useMemo(() => {
        const date = new Date();
        date.setMonth(date.getMonth() - 18);
        return date;
    }, []);

    const [paid, setPaid] = useState(null);
    const [fifo, setFifo] = useState(null);
    const [trial, setTrial] = useState(null);
    const [transit, setTransit] = useState(null);

    const computeMRR = useCallback(() => {
        const months = {};
        const trialMonths = {};
        const millisecondEquivalentTo7days = 7 * 24 * 60 * 60 * 1000;

        const addPayments = payments => {
            Object.values(payments || {}).forEach(payment => {
                const paymentDate = new Date(payment.created_at);
                if (paymentDate.getTime() >= startDate.getTime()) {
                    const month = paymentDate.getFullYear() + "-" + (paymentDate.getMonth() + 1);
                    months[month] = (months[month] || 0) + parseFloat(payment.net);
                }
            });
        };

        Object.values(trial || {}).forEach(checkout => {
            const checkoutDate = new Date(checkout.customer_details.created * 1000 + millisecondEquivalentTo7days);
            const month = checkoutDate.getFullYear() + "-" + (checkoutDate.getMonth() + 1);
            trialMonths[month] = (trialMonths[month] || 0) + parseFloat((checkout?.plan?.quantity || 1) * 30);
        });

        addPayments(paid);
        addPayments(fifo);
        addPayments(transit);

        Object.keys(trialMonths).forEach(month => {
            months[month] = months[month] || 0;
        });

        return { months, trialMonths };
    }, [paid, fifo, trial, transit, startDate]);

    useEffect(() => {
        const loadInfosFromSaas = async () => {
            try {
                firebase.db.ref('business/saas').on('value', snapshot => {
                    setPaid(snapshot.val()?.paid);
                    setFifo(snapshot.val()?.fifo);
                    setTrial(snapshot.val()?.checkouts);
                    setTransit(snapshot.val()?.transit);
                });
            } catch (error) {
                console.error("Failed to load data from Firebase:", error);
            }
        };

        loadInfosFromSaas();
        return () => firebase.db.ref('business/saas').off();
    }, [firebase]);

    const { months, trialMonths } = useMemo(() => computeMRR(), [computeMRR]);

    return (
        <div>
            {tab === SUB_PANELS.MRR_RECAP && <MRRRecap {...{ months, trialMonths, ...props }} />}
            {tab === SUB_PANELS.PAYOUTS && <Payout {...{ months, fifo, transit, ...props }} />}
        </div>
    );
}

export { SUB_PANELS };
export default LowTouchMRR;
