import Chart from "react-google-charts";
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React, { useMemo, useEffect, useState } from 'react';
import DescriptionIcon from '@material-ui/icons/Description';
import FloatingCard from '../../../utils/GenericComponents/floatingCard';

import { useStyles, chartOptions } from './styles';

const MRRRecap = (props) => {
    const classes = useStyles();
    const { months, trialMonths } = props;

    // État pour gérer si le composant est monté
    const [isMounted, setIsMounted] = useState(true);

    useEffect(() => {
        return () => { setIsMounted(false); };
    }, []);
        

    const DisplayChart = useMemo(() => {
        return (props) => {
            if (!isMounted) return null;  // Ne pas rendre si le composant n'est plus monté
            return (
                <Chart
                    data={props.mrr}
                    options={chartOptions}
                    chartType="ColumnChart"
                    rootProps={{ 'data-testid': props.id }}
                    loader={<div>Loading Chart...</div>}
                />
            );
        };
    }, [isMounted]);

    /*
    const DisplayChart = props => {
        if (!isMounted) return null;
        return (
            <Chart
                data={props.mrr}
                options={chartOptions}
                chartType="ColumnChart"
                rootProps={{ 'data-testid': props.id }}
                loader={<div>Loading Chart...</div>}
            />
        )
    }
    */

    const mrr = useMemo(() => {
        const data = [['Month', 'Amount', { role: 'annotation' }, 'Trial', { role: 'annotation'}]];
        Object.keys(months).forEach((month) => {
             const value = months[month];
             const trialValue = trialMonths[month] || 0;
             const trialSymbol = trialValue ? trialValue.toFixed(0) + "€" : "";
             data.push([month, value, value.toFixed(0) + "€", trialValue, trialSymbol]);
        });
        return data;
    }, [months, trialMonths]);

    const mrrVariation = useMemo(() => {
        const data = [['Month', 'Percentage', { role: 'annotation' }, { role: "style" }]];
        Object.keys(months).forEach((month, index) => {
            if (index === 0) {
                data.push([month, 0, "0%", "color: green"]);
            } else {
                const currentMonthValue = months[month];
                const previousMonthValue = months[Object.keys(months)[index - 1]];
                const variation = ((currentMonthValue - previousMonthValue) / previousMonthValue) * 100;
                data.push([month, variation, variation.toFixed(0) + "%", variation > 0 ? "color: green" : "color: red"]);
            }
        });
        return data;
    }, [months]);

    const average = useMemo(() => {
        return (mrrVariation.slice(1, mrrVariation.length - 1).reduce((total, month) => total + month[1], 0) / mrrVariation.slice(1, mrrVariation.length - 1).length).toFixed(0);
    }, [mrrVariation]);
    
    //const average = (mrrVariation.slice(1, mrrVariation.length - 1).reduce((total, month) => total + month[1], 0) / mrrVariation.slice(1, mrrVariation.length - 1).length).toFixed(0);

    return (
        <FloatingCard
            noflex={true}
            avatar={<DescriptionIcon />}
            title={`MRR Low touch (last 18 months)`}
        >
            <Grid container spacing={2} justifyContent="center" alignItems="flex-end" className={classes.grid}>
                <Grid item xs={12} sm={12} className={classes.gridItemFifo}>
                    <Typography variant="h6" className={classes.title}>
                        MRR Evolution
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} className={classes.gridItemFifo}>
                    <DisplayChart mrr={mrr} id={1}/>
                </Grid>
                <Grid item xs={12} sm={12} className={classes.gridItemFifo}>
                    <Typography variant="h6" className={classes.title}>
                        MRR Growth (Average {average > 0 ? "+" : ""}{average}%)
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} className={classes.gridItemFifo}>
                    <DisplayChart mrr={mrrVariation} id={2}/>
                </Grid>
            </Grid>
        </FloatingCard>
    );
}

export default MRRRecap;