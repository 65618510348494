import { Grid } from '@material-ui/core';
import PaymentsRecapPanel from './paymentRecapPanel';
import Typography from '@material-ui/core/Typography';
import DescriptionIcon from '@material-ui/icons/Description';
import React, { useEffect, useState, useCallback } from 'react';
import CreateNewPayoutInTransit from './createNewPayoutInTransit';
import FloatingCard from '../../../utils/GenericComponents/floatingCard';

import { useStyles } from './styles';

const Payout = (props) => {
    const classes = useStyles();
    const { months, fifo, transit } = props;
    const [totalFifo, setTotalFifo] = useState(0);
    const [totalTransit, setTotalTransit] = useState(0);
    const [lastMonthMrr, setLastMonthMrr] = useState(0);
    const [currentMonthMrr, setCurrentMonthMrr] = useState(0);

    useEffect(() => {
        const _currentMonthMrr = Object.values(months || {})[Object.values(months || {}).length - 1];
        let offset = _currentMonthMrr === 0 ? 1 : 0;
        setLastMonthMrr(Object.values(months || {})[Object.values(months || {}).length - (2 + offset)] || 0);
        setCurrentMonthMrr(Object.values(months || {})[Object.values(months || {}).length - (1 + offset)] || 0);
        setTotalFifo(Object.values(fifo || {}).reduce((total, payment) => total + parseFloat(payment.net), 0));
        setTotalTransit(Object.values(transit || {}).reduce((total, payment) => total + parseFloat(payment.net), 0));

    }, [months, fifo, transit]);

    const onCreateNewPayoutInTransit = useCallback((payout) => {
        const key = Date.now();
        props.firebase.db.ref('business/saas/transit').update({ [key]: payout });
    }, [props.firebase]);
        
    return (
        <FloatingCard
            noflex={true}
            avatar={<DescriptionIcon />}
            title={`Payouts - This month: ${(currentMonthMrr).toFixed(2)}€ (Last month: ${(lastMonthMrr).toFixed(2)}€)`}
        >
            <Grid container spacing={3} justifyContent="center" alignItems="flex-end" className={ classes.grid }>
                <Grid item xs={12} sm={6} className={ classes.gridItemFifo }>
                    <PaymentsRecapPanel
                        list={fifo}
                        type='fifo'
                        amount={totalFifo.toFixed(2)}
                    />
                    <Typography variant="caption" color='textSecondary' align='center' >Payments succeeded in Stripe</Typography>
                </Grid>
                <Grid item xs={12} sm={6} className={ classes.gridItemTransit }>
                    <PaymentsRecapPanel
                        list={transit}
                        type='transit'
                        amount={totalTransit.toFixed(2)}
                    />
                    <Typography variant="caption" color='textSecondary' align='center' >Payments sent out from Stripe</Typography>
                </Grid>
            </Grid>
            <CreateNewPayoutInTransit onCreateNewPayoutInTransit={ onCreateNewPayoutInTransit } />
        </FloatingCard>
    );
}    

export default Payout;